<template>
  <div class="py-3" :class="{ 'border-b border-black/6': showBorder }">
    <div class="flex w-full flex-1 items-center justify-between">
      <div class="mr-2 flex w-3/12 justify-center">
        <NuxtLink
          class="m-auto"
          :to="link"
          @click="
            trackProductClick();
            $emit('product-click');
          "
        >
          <img
            class="object-contain mix-blend-darken"
            style="max-height: 120px"
            :src="image || productImagePlaceholder"
            :alt="title"
          />
        </NuxtLink>
      </div>

      <div class="flex w-8/12 grow flex-col">
        <div class="flex flex-col" :class="{ 'mb-4': enableQuantitySelector }">
          <span
            v-if="brand && brand.name && animalType"
            class="w-full text-xs text-black/54"
          >
            {{ brand.name }} / {{ animalType }}
          </span>
          <div
            class="flex items-baseline"
            :class="!frequencyInDays ? 'mb-4' : 'mb-2'"
          >
            <NuxtLink
              :to="link"
              class="font-bold text-black/87 hover:underline"
              data-test-id="product-header-title"
              @click="
                trackProductClick();
                $emit('product-click');
              "
            >
              {{ title }}
            </NuxtLink>

            <span
              v-if="storageClimate === productStorageClimate.Frozen"
              class="ml-2"
            >
              ({{ $t('cart.frozen_food') }})
            </span>

            <span
              v-if="!enableQuantitySelector && showQuantity"
              class="ml-2 flex-none text-black/54"
            >
              x {{ quantity.value }}
            </span>
          </div>
          <div
            v-if="frequencyInDays"
            class="flex w-full items-center text-xs text-black/54"
            :class="{ 'mb-4': enableQuantitySelector || options.length }"
            data-test-id="product-header-subscription-frequency"
          >
            <DynamicPictogram name="subscriptions" class="mr-2" size="lg" />
            <SubscriptionFrequencyLabel
              :frequency-in-days="props.frequencyInDays"
            />
          </div>
          <slot name="options" />
        </div>
        <slot name="quantity-selector" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import productImagePlaceholderPng from '@/assets/images/product-image-placeholder.png';
import {
  ProductStorageClimate,
  type Product as GqlProduct,
} from '#root/shared/types/graphql-types';

const gtm = useGtm();

const localePath = useLocalePath();

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  image: {
    type: String,
    default: '',
  },
  brand: {
    type: Object as PropType<GqlProduct['brand']>,
    default: () => ({}),
  },
  categories: {
    type: Array,
    default: () => [],
  },
  animalType: {
    type: String,
    default: '',
  },
  path: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  },
  quantity: {
    type: Object,
    default: () => ({
      value: 0,
    }),
  },
  trackingContextData: {
    type: Object,
    default: () => ({}),
  },
  storageClimate: {
    type: String,
    default: 'ambient',
  },
  frequencyInDays: {
    type: Number,
    default: 0,
  },
  enableQuantitySelector: {
    type: Boolean,
    default: true,
  },
  trackingProductData: {
    type: Object,
    default: () => ({}),
  },
  showQuantity: {
    type: Boolean,
    default: true,
  },
  showBorder: {
    type: Boolean,
    default: true,
  },
});

defineEmits(['product-click']);

const productImagePlaceholder = ref(productImagePlaceholderPng);
const productStorageClimate = ref(ProductStorageClimate);

const link = computed(() => {
  const pathParts = props.path.split('/');
  const slug = pathParts.pop() || pathParts.pop(); // handle potential trailing slash

  return slug
    ? localePath({ name: 'shop-product-slug', params: { slug } })
    : '';
});

const trackProductClick = () => {
  const { position, list } = props.trackingContextData;
  gtm?.trackEvent(
    productClickEvent(
      [
        {
          ...props.trackingProductData,
          position,
        },
      ],
      list
    )
  );
};
</script>
